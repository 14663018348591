<template>
  <div class="container">

    <div v-if="(this.activeSuccess || this.saveCardSuccess) && this.showHtml">
      <Receipt :transaction="transactionDetails" :settings="settings"/>
      <p v-if="transactionDetails?.payment_component !== 'MANUAL_INVOICE' && this.activeTimer" class="p_label">
        Redirecting in {{
          timerCount
        }}...</p>
    </div>

    <div v-if="this.activeFailed">
      <LottieAnimation
          ref="anim"
          :animationData="require('@/assets/lottie/payment-failed.json')"
          :loop="3"
          :autoPlay="true"/>
      <p class="p_label">{{ this.errorMsg }}</p>
    </div>

    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="!showHtml" class="main_container"></div>
      <div class="row justify-content-center" v-if="showHtml && this.activePaymentPage">
        <div class="col-xl-6">
          <div class="card margin_top p-4">

            <div class="row justify-content-center mb-4">
              <img class="img-fluid" style="width: 120px"
                   v-if="this.settings?.logo_url" :src="this.settings?.logo_url"
                   alt="logo"/>
            </div>

            <div id="address-element"></div>

            <div id="payment-element"></div>

            <div style="font-size: x-small; color: gray;padding: 8px">
              This order process is conducted by online reseller & Merchant of Record, Stykite. Your data will be
              shared with {{ this.settings?.merchant_name }} for order fulfilment. Stykite, Inc, Suite 484, 2093A,
              Philadelphia Pike,
              Claymont,
              Delaware 19703
              <a target="_blank" :href="tandc">Terms & Conditions</a>
              &nbsp;
              <a target="_blank" :href="privacyPolicy">Privacy Policy</a>
            </div>

            <div class="row mt-4 justify-content-center">
              <button class="btn_call btn_call_inner" @click="pay">
                {{
                  transactionDetails?.payment_component === 'SAVE_CARD' ? 'Save Card' : (transactionDetails?.currency_symbol) + (transactionDetails?.amount ? (transactionDetails?.amount / 100).toFixed(2) : '') + ' Pay Now'
                }}
              </button>
            </div>

          </div>
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import LottieAnimation from 'lottie-web-vue'
import axios from 'axios'
import Receipt from "../components/Receipt/index.vue";


export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      showHtml: false,
      stripeElements: null,
      showOverlay: false,
      activeTimer: false,
      activeSuccess: false,
      activeFailed: false,
      activePaymentPage: true,
      saveCardSuccess: false,
      timerCount: 3,
      errorMsg: null,
      tandc: process.env.VUE_APP_TERMS_AND_CONDITIONS,
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      transactionTsid: null,
      transactionDetails: null,
      settings: null
    }
  },
  components: {
    LottieAnimation,
    Receipt
  },
  validations() {
    return {}
  },
  computed: {},
  watch: {
    timerCount: {
      handler(value) {
        if (this.activeTimer) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--
            }, 1000)
          } else {
            if (this.transactionDetails?.payment_component !== 'MANUAL_INVOICE') {
              window.location = this.settings?.redirect_url_after_payment
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async pay() {
      this.showOverlay = true
      const razorpayOrderId = this.transactionDetails.gateway_reference_id;
      const options = {
        "name": this.settings?.merchant_name,
        "description": "",
        "image": this.settings?.logo_url,
        "prefill": {
          "email": this.transactionDetails?.customer?.email,
          "contact": this.transactionDetails?.customer?.phone,
        },
        "notes": {},
        "theme": {
          "color": "#F37254"
        },
        "order_id": `${razorpayOrderId}`,
      }
      if (this.transactionDetails?.payment_component === 'PLAN' ||
          this.transactionDetails?.payment_component === 'ADD_ON' ||
          this.transactionDetails?.payment_component === 'SAVE_CARD') {
        options['config'] = this.razorpayOlyCardAndUPI()
      }
      console.log(JSON.stringify(options))
      options.theme.image_padding = false;
      options.handler = this.onPaymentComplete;
      options.modal = {
        ondismiss: this.onModalClose,
        escape: true,
        backdropclose: false
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    },
    onPaymentComplete(res) {
      const razorpayOrderId = this.transactionDetails.gateway_reference_id;
      console.log(res.razorpay_payment_id, razorpayOrderId, res.razorpay_signature)
      this.showOverlay = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      this.activeTimer = true
      this.timerCount--
    },
    onModalClose() {
      this.showOverlay = false
    },
    razorpayOlyCardAndUPI() {
      return {
        display: {
          blocks: {
            banks: {
              name: 'Pay via UPI or Card',
              instruments: [
                {
                  method: 'upi'
                },
                {
                  method: 'card'
                }
              ],
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks: false,
          },
        },
      }
    },
    async getTransactionDetails() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/transaction/${this.transactionTsid}`)
        return data.value
      } catch (e) {
        console.log(e)
      }
      return null
    },
    async getSettings() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/settings?type=WORKFLOW_SETTINGS`)
        if (data && data.value) {
          this.settings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  ,
  async mounted() {
    this.showOverlay = true
    this.transactionTsid = this.$route.params.transactionTsid

    this.transactionDetails = await this.getTransactionDetails();
    await this.getSettings();
    this.showHtml = true

    // show success page if transaction already completed
    if (this.transactionDetails.status === 'SUCCESS') {
      this.showOverlay = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      return
    }

    this.showOverlay = false
  }
  ,
}
</script>

<style scoped>

#card-error {
  color: red;
}

.labelStyle {
  font-family: 'Poppins';
  font-size: 15px;
  font-variant: normal;
  font-weight: lighter;
}

.p_label {
  font-family: 'Poppins';
  font-size: 15px;
  text-align: center;
  font-variant: normal;
  font-weight: lighter;
}

.card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: 0.8rem;
  padding: 10px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.border {
  border-radius: 0.8rem;
  border: 1px solid #bdd1e5 !important;
}

.margin_top {
  margin-top: 1rem !important
}

.btn_call {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #4716DF !important;
  border: 1px solid #FFFFFF;
}

.btn_call_inner {
  color: #FFFFFF !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lineHeight {
  line-height: 40px !important;
}

.lineHeight:focus-visible {
  outline: 1px solid #ffffff !important;
}

.lineHeight:focus {
  outline: 1px solid #ffffff !important;
  box-shadow: 0 0 0 0.5rem #ffffff !important;
}

.card_details {
  width: 50% !important;
  margin: auto !important;
}

.main_container {
  height: 90vh !important;
}

</style>
