<template>
  <div class="container">

    <div v-if="(this.activeSuccess || this.saveCardSuccess) && this.showHtml">
      <Receipt :transaction="transactionDetails" :settings="settings"/>
      <p v-if="transactionDetails?.payment_component !== 'MANUAL_INVOICE' && this.activeTimer" class="p_label">
        Redirecting in {{
          timerCount
        }}...</p>
    </div>

    <div v-if="this.activeFailed">
      <LottieAnimation
          ref="anim"
          :animationData="require('@/assets/lottie/payment-failed.json')"
          :loop="3"
          :autoPlay="true"/>
      <p class="p_label">{{ this.errorMsg }}</p>
    </div>

    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="!showHtml" class="main_container"></div>
      <div class="row justify-content-center" v-if="showHtml && this.activePaymentPage">
        <div class="col-xl-6">
          <div class="card margin_top p-4">

            <div class="row justify-content-center mb-4">
              <img class="img-fluid" style="width: 120px"
                   v-if="this.settings?.logo_url" :src="this.settings?.logo_url"
                   alt="logo"/>
            </div>

            <div id="address-element"></div>

            <div id="payment-element"></div>

            <div style="font-size: x-small; color: gray;padding: 8px">
              This order process is conducted by online reseller & Merchant of Record, Stykite. Your data will be
              shared with {{ this.settings?.merchant_name }} for order fulfilment. Stykite, Inc, Suite 484, 2093A,
              Philadelphia Pike,
              Claymont,
              Delaware 19703
              <a target="_blank" :href="tandc">Terms & Conditions</a>
              &nbsp;
              <a target="_blank" :href="privacyPolicy">Privacy Policy</a>
            </div>

            <div class="row mt-4 justify-content-center">
              <button class="btn_call btn_call_inner" @click="pay">
                {{
                  transactionDetails?.payment_component === 'SAVE_CARD' ? 'Save Card' : (transactionDetails?.currency_symbol) + (transactionDetails?.amount ? (transactionDetails?.amount / 100).toFixed(2) : '') + ' Pay Now'
                }}
              </button>
            </div>

          </div>
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {StripePlugin} from '@vue-stripe/vue-stripe'
import LottieAnimation from 'lottie-web-vue'
import Vue from 'vue'
import axios from 'axios'
import Receipt from "../components/Receipt/index.vue";
import {setValueWithMissingObject} from "@/util/commonUtil";


export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      showHtml: false,
      stripeElements: null,
      showOverlay: false,
      activeTimer: false,
      activeSuccess: false,
      activeFailed: false,
      activePaymentPage: true,
      saveCardSuccess: false,
      timerCount: 3,
      errorMsg: null,
      tandc: process.env.VUE_APP_TERMS_AND_CONDITIONS,
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      transactionTsid: null,
      transactionDetails: null,
      settings: null
    }
  },
  components: {
    LottieAnimation,
    Receipt
  },
  validations() {
    return {}
  },
  computed: {},
  async mounted() {
    this.showOverlay = true
    this.transactionTsid = this.$route.params.transactionTsid

    //This is for resultId first test transaction raised before code refactoring
    //Remove once this transaction is SUCCESS
    if (this.transactionTsid === 'pi_3P3JOXDD7hrq7AJL1qQyCpB7_secret_jGPi6oyC3wJ9DucDTGs8jWyIH') {
      this.transactionTsid = 'TRN-0FNZGEBRFCXNP'
    }

    this.transactionDetails = await this.getTransactionDetails();

    //if no country or zip code then auto-detect from ip
    if (!this.transactionDetails?.customer?.billing_address?.country_code ||
        !this.transactionDetails?.customer?.billing_address?.postal_code) {
      await this.autoDetectCountryAndPostalCode()
    }

    await this.getSettings();
    this.showHtml = true

    // show success page if transaction already completed
    if (this.transactionDetails.status === 'SUCCESS') {
      this.showOverlay = false
      this.activePaymentPage = false
      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        this.saveCardSuccess = true
      } else {
        this.activeSuccess = true
      }
      return
    }

    //stripe init
    const clientSecret = this.transactionDetails.gateway_reference_id
    const pk = await this.getStripKey();
    const soptions = {
      pk: pk,
    }
    Vue.use(StripePlugin, soptions)

    const appearance = {
      theme: 'stripe',
    }
    this.stripeElements = this.$stripe.elements({appearance, clientSecret})
    const paymentElementOptions = {
      layout: 'accordion',
      fields: {
        billingDetails: {
          email: 'never',
          name: 'never',
          address: {
            country: 'never',
            postalCode: 'never'
          }
        }
      }
    }
    const paymentElement = this.stripeElements.create('payment', paymentElementOptions)
    paymentElement.mount('#payment-element')

    /*const options = {mode: 'billing'}
    const addressElement = this.stripeElements.create('address', options)
    addressElement.mount('#address-element')*/

    this.showOverlay = false
  },
  beforeDestroy() {
  },
  watch: {
    timerCount: {
      handler(value) {
        if (this.activeTimer) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--
            }, 1000)
          } else {
            if (this.transactionDetails?.payment_component !== 'MANUAL_INVOICE') {
              window.location = this.settings?.redirect_url_after_payment
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async pay() {
      this.showOverlay = true
      let result = null

      const paymentMethod = {
        billing_details: {
          name: (`${this.transactionDetails?.customer?.first_name} ${this.transactionDetails?.customer?.last_name}`).trim() || '',
          email: this.transactionDetails?.customer?.email || '',
          address: {
            country: this.transactionDetails?.customer?.billing_address?.country_code,
            postal_code: this.transactionDetails?.customer?.billing_address?.postal_code
          }
        }
      };

      if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
        result = await this.$stripe.confirmSetup({
          elements: this.stripeElements,
          confirmParams: {payment_method_data: paymentMethod},
          redirect: 'if_required'
        })
      } else {
        result = await this.$stripe.confirmPayment({
          elements: this.stripeElements,
          confirmParams: {payment_method_data: paymentMethod},
          redirect: 'if_required'
        })
      }
      this.showOverlay = false


      if (!result || result.error) { //Failed
        if ('validation_error' !== result.error.type) {
          //this.activeFailed = true
          this.errorMsg = result.error.message
          this.$notify({group: 'foo', type: 'error', title: 'Error', text: this.errorMsg});
        }
      } else { //Success
        this.activePaymentPage = false
        if (this.transactionDetails?.payment_component === 'SAVE_CARD') {
          this.saveCardSuccess = true
        } else {
          this.activeSuccess = true
        }
        this.activeTimer = true
        this.timerCount--
      }
    },
    async getStripKey() {
      const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/stripe`)
      if (data && data.value) {
        return data.value
      }
      return null
    },
    async getTransactionDetails() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/public/transaction/${this.transactionTsid}`)
        return data.value
      } catch (e) {
        console.log(e)
      }
      return null
    },
    async getSettings() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/company/${this.transactionDetails?.company_tsid}/country/${this.transactionDetails?.country_tsid}/settings?type=WORKFLOW_SETTINGS`)
        if (data && data.value) {
          this.settings = {...data.value.setting}
        }
      } catch (e) {
        console.log(e)
      }
    },
    async autoDetectCountryAndPostalCode() {
      try {
        const {data} = await axios.get(`${process.env.VUE_APP_BASE_URL_SUBSCRIPTION_SERVICE}/v1/ip2location`)
        if (data && data.value) {
          const countryCode = data.value.countryCode
          const zip = data.value.zip
          if (!this.transactionDetails?.customer?.billing_address?.country_code) {
            setValueWithMissingObject(this.transactionDetails, 'customer.billing_address.country_code', countryCode)
          }
          if (!this.transactionDetails?.customer?.billing_address?.postal_code) {
            setValueWithMissingObject(this.transactionDetails, 'customer.billing_address.postal_code', zip)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

#card-error {
  color: red;
}

.labelStyle {
  font-family: 'Poppins';
  font-size: 15px;
  font-variant: normal;
  font-weight: lighter;
}

.p_label {
  font-family: 'Poppins';
  font-size: 15px;
  text-align: center;
  font-variant: normal;
  font-weight: lighter;
}

.card {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: 0.8rem;
  padding: 10px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
}

.border {
  border-radius: 0.8rem;
  border: 1px solid #bdd1e5 !important;
}

.margin_top {
  margin-top: 1rem !important
}

.btn_call {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #4716DF !important;
  border: 1px solid #FFFFFF;
}

.btn_call_inner {
  color: #FFFFFF !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lineHeight {
  line-height: 40px !important;
}

.lineHeight:focus-visible {
  outline: 1px solid #ffffff !important;
}

.lineHeight:focus {
  outline: 1px solid #ffffff !important;
  box-shadow: 0 0 0 0.5rem #ffffff !important;
}

.card_details {
  width: 50% !important;
  margin: auto !important;
}

.main_container {
  height: 90vh !important;
}

</style>
